import { InterInstance, InterInstanceAssistans } from './config';

export const createEmission = async ( token:any,obj: any) => {
  try {
    const { data } = await InterInstance({
      method: 'post',
      url: '/reEmision',
      headers: { Authorization: `Bearer ${token}` },
      data: obj,
    });
    return data;
  } catch (e) {
    return e;
  }
}
export const createEmissionAssistants = async ( token:any,obj: any) => {
  try {
    const { data } = await InterInstanceAssistans({
      method: 'post',
      url: '/metlife/reEmision',
      headers: { Authorization: `Bearer ${token}` },
      data: obj,
    });
    return data;
  } catch (e) {
    return e;
  }
}