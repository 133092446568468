import { makeStyles } from "@mui/styles";
import { Routes, Route, Link } from "react-router-dom";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { themeOptions } from "./theme/theme";
import { Payment } from "./pages/Payment";
import { ResponseInsurance } from "./screens/insurance/ResponseInsurance";
import { PanelZurich } from "./screens/insurance/PanelZurich";
import { Paymit } from "./pages/Paymit";
import { PaymitAssistants } from "./pages/Paymit_assistans";

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
  content: {
    width: "100%",
    overflow: "auto",
  },
});

function App() {
  const classes = useStyles();
  return (
    <Routes>
      <Route path="/" element={
        (
          <ThemeProvider theme={theme}>
            <main className={classes.content}>
              <Payment></Payment>
            </main>
          </ThemeProvider>
        )
      } />
      <Route path="/aba" element={<ResponseInsurance />} />
      <Route path="/zurich" element={<PanelZurich />} />
      <Route path="/mit" element={<Paymit />} />
      <Route path="/assistants/mit" element={<PaymitAssistants />} />
    </Routes>
  );
}

export default App;
