import './Modal.css';
import { CircularProgress } from '@mui/material';
import Modal from '@mui/material/Modal';
import logo from './tube-spinner.svg';

export default function LoadingGeneral(props) {
  return (
    <Modal size="lg" open={props.open} onClose={props.handleClose}>
      <div className="modal-contenedor">
        <div className="modal-loading">
          <img
            className="logo"
            src= {props.src ? require(`${props.src}`) : logo}
            width="600px"
            height="210px"
            alt=""
          />
        </div>
      </div>
    </Modal>
  );
}
