import { useState } from "react";

export const getAccessToken = async(): Promise<void> => {
    const url = `${process.env.REACT_APP_MIT_API}/authorization/token`;
    const body = {
      "client_id": process.env.REACT_APP_CLIENT_ID_MIT,
      "client_secret": process.env.REACT_APP_CLIENT_SECRET_MIT
    };
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json());
      if (res.success && res.data.access_token) {
        localStorage.setItem('token_mit', res.data.access_token);
      } else {
          console.log(`Ha ocurrido un error al obtener el token. Si el problema persiste contacte a soporte.`)
          
      }
    } catch (error) {
      console.log(error);
    }
  }

  export const getAccessTokenAssistans = async(): Promise<void> => {
    const url = `${process.env.REACT_APP_ASSISTANTS}/authorization/token`;
    const body = {
      "client_id": process.env.REACT_APP_CLIENT_ID_ASSISTANTS,
      "client_secret": process.env.REACT_APP_CLIENT_SECRET_ASSISTANTS
    };
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json());
      if (res.success && res.data.access_token) {
        localStorage.setItem('token_mit', res.data.access_token);
      } else {
          console.log(`Ha ocurrido un error al obtener el token. Si el problema persiste contacte a soporte.`)
          
      }
    } catch (error) {
      console.log(error);
    }
  }